import React from 'react';

const Career = (props) => {
 return(
 <div className="common__contents-center">
     <h3>Career</h3>
 
 </div>
 )
}

export default Career;